<template>
  <div>
    <b-card>
        <b-row class="px-2">
          <b-col cols="12">
            <div class="w-75 mx-auto d-flex flex-column justify-content-center align-items-start redirect-wrapper">
              <div class="w-100 d-flex justify-content-center align-items-center redirect-text" v-if="!isCancelRedirect">Redirecting to Edu Shop...</div>
              <div class="w-100 d-flex justify-content-center align-items-center redirect-text" v-else>Redirecting to Edu Shop</div>
              <div class="w-100 d-flex justify-content-center align-items-center text-secondary custom-text-stop" v-if="isCancelRedirect">Redirection cancelled!</div>
              <div class="spinner-custom" v-else>
                <div class="bounce1-custom"></div>
                <div class="bounce2-custom"></div>
                <div class="bounce3-custom"></div>
              </div>
            </div>
          </b-col>
          <b-col class="text-center">
              <b-button variant="warning" v-if="!isCancelRedirect" @click="cancelRedirect">
                  Cancel
              </b-button>
              <b-button variant="primary" v-else @click="goToShop()">
                  Switch to Shop
              </b-button>
          </b-col>
        </b-row>
    </b-card>
  </div>
</template>
<script>
import { useRouter } from "@/@core/utils/utils";
import {
    defineComponent, 
    onMounted, 
    ref, 
    watch 
} from "@vue/composition-api";

export default defineComponent({
  setup() {
    const redirectModal = ref(true);
    const { router } = useRouter();
    const isCancelRedirect = ref(false);
    let shopPath = "https://trec.tranzak.me/product/list";
    let redirectTimout;

    const redirectToPage = () => {
      redirectTimout = setTimeout(() => {
        window.location.replace(shopPath);
      }, 5000);
    };

    const goToShop = () => {
        window.location.replace(shopPath);
    }

    const cancelRedirect = () => {
      isCancelRedirect.value = true;
    };

    onMounted(() => {
      redirectToPage();
    });

    watch(isCancelRedirect, (newVal, oldVal) => {
      // console.log(newVal)
      clearTimeout(redirectTimout);
    });

    return {
      redirectModal,
      isCancelRedirect,
      cancelRedirect,
      goToShop
    };
  },
});
</script>
<style scoped>
.redirect-wrapper {
  position: relative;
}
.redirect-text {
  font-size: 2rem;
  text-align: center;
}
.spinner-custom {
  margin: 0 auto;
  width: 70px;
  height: 150px;
  text-align: center;
  padding-top: 20px;
  /* position: absolute;
  left: 40%; */
}

.custom-text-stop {
    text-align: center;
    min-height: 150px;
}

.spinner-custom > div {
  width: 6px;
  height: 6px;
  background-color: #990099;
  border-radius: 100%;
  display: inline-block;
  -webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
  animation: sk-bouncedelay 1.4s infinite ease-in-out both;
}

.spinner-custom .bounce1-custom {
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}

.spinner-custom .bounce2-custom {
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}

@-webkit-keyframes sk-bouncedelay {
  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1);
  }
}

@keyframes sk-bouncedelay {
  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
</style>
